<template>
  <div>
    <div class="order flex">
      <div class="orderLeftContainer">
        <el-popover placement="top" trigger="hover">
          <div style="width: 220px">
            {{ $fanyi("点击箱子编号或者箱子规格，可以查看具体装箱明细") }}
          </div>
          <div slot="reference" class="hint">?</div>
        </el-popover>
        <ul class="header flex">
          <li class="boxNumber">{{ $fanyi("箱号") }}</li>
          <li class="actualWeight">{{ $fanyi("箱规") }}（cm）</li>
          <li class="actualWeight">{{ $fanyi("体积") }}（cm³）</li>
          <li class="actualWeight">{{ $fanyi("实际重量") }}（kg）</li>
          <li class="actualWeight"><span>{{ $fanyi("容积重量") }}（kg）</span></li>
          <li class="logisticsMode">{{ $fanyi("物流方式") }}</li>
          <li class="otherExpenses">{{ $fanyi("其他费用") }}</li>
          <li class="logisticsNumber">{{ $fanyi("国际物流单号") }}</li>
        </ul>
        <div class="goodsBox">
          <ul v-for="(item, itemIndex) in table" :key="itemIndex" class="goodsConUl flexAndCenter"
              style="cursor: pointer" @click="deliveryEncasementDetailsPageJump(item.porder_sn)">
            <!--            箱号-->
            <li class="boxNumber flexAndCenterAndCenter">{{ item.number }}</li>
            <!--            箱规-->
            <li class="actualWeight flexAndCenterAndCenter">
              {{ item.width + '*' + item.length + '*' + item.height }}
            </li>
            <!--            体积-->
            <li class="actualWeight flexAndCenterAndCenter">{{ item.volume }}</li>
            <!--            实际重量-->
            <li class="actualWeight flexAndCenterAndCenter">{{ item.weight }}</li>
            <!--            容积重量-->
            <li class="actualWeight flexAndCenterAndCenter">{{ item.volume_weight }}</li>
            <!--            物流方式-->
            <li class="logisticsMode flexAndCenterAndCenter u-line" :title="item.logistics_name">
              <div class="u-line" style="width: 100px" :title="item.logistics_name">{{
                  item.logistics_name
                }}
              </div>
            </li>
            <!--            其他费用-->
            <li class="otherExpenses flexAndCenterAndCenter">
              <div class="flexAndCenterAndCenter">
                <div>{{ item.other_amount }}</div>
              </div>
            </li>
            <!--            国际物流单号-->
            <li class="logisticsNumber flexAndCenterAndCenter " :title="item.express_no"
                @click.stop="logisticsSelectPageJump(item)">
              <el-popover
                  v-if="item.logistics_name == 'TW船便' || item.logistics_name == '海源DQ船便'|| item.logistics_name == 'RW船便'||item.logistics_name == 'Raku-KS' || item.logistics_name == 'EMS' || item.logistics_name == 'OCS' || item.logistics_name == 'KS-JP航空便'|| item.logistics_name == 'Raku-DQ'|| item.logistics_name == 'DQE'"
                  placement="left" style="width: 500px;" trigger="hover">
                <div style="width: 500px;max-height: 500px; overflow-y: scroll">
                  <el-timeline style="margin-left: 2px">
                    <el-timeline-item v-for="(activity, activityIndex) in item.activities" :key="activityIndex"
                                      :color="activity.color"
                                      :style="activityIndex === item.activities.length - 1 ? 'padding-bottom:0' : ''"
                                      placement="bottom">
                      <div
                          :style="activityIndex === 0 ? 'color: #B4272B;margin-bottom:8px' : 'margin-bottom:8px;color: #000000;'"
                          class="fontSize14">
                        {{ activity.address }}
                      </div>
                      <div :style="activityIndex === 0 ? 'color: #B4272B;' : ''" class="color999 fontSize12">{{
                          activity.time
                        }}
                      </div>
                    </el-timeline-item>
                  </el-timeline>
                </div>
                <div slot="reference" class="u-line fontSize14"
                     style="width: 130px;text-align: center;cursor: pointer;color: #2742B4;text-decoration: underline;"
                     @click="logisticsSelectPageJump(item)" @mouseenter="logisticsMouseenter(item)">{{
                    item.express_no
                  }}
                </div>
              </el-popover>
              <div v-else class="u-line fontSize14" @click.stop="logisticsSelectPageJump(item)"
                   style="width: 130px;text-align: center;cursor: pointer;color: #2742B4;text-decoration: underline;">
                {{ item.express_no }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="orderRightContainer">
        <ul class="header flex">
          <li class="logisticsMode">{{ $fanyi("发送方式") }}</li>
          <li class="billingData">{{ $fanyi("计费数据") }}</li>
          <li class="logisticsMode">{{ $fanyi("费用（元）") }}</li>
        </ul>
        <div class="goodsBox">
          <ul v-for="(item, itemIndex) in logisticsGroupTable" :key="itemIndex" class="goodsConUl flexAndCenter"
              style="cursor: pointer">
            <li class="logisticsMode flexAndCenterAndCenter">{{ item.logistics_name }}</li>
            <li class="billingData flexAndCenterAndCenter">{{ item.amount_data }}</li>
            <li class="logisticsMode flexAndCenterAndCenter">{{ item.total_money_rmb }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    table: {//箱子数据
      type: Array,
      default: [],
    },
    logisticsGroupTable: {//物流汇总数据
      type: Array,
      default: [],
    }
  },
  data() {
    return {
      otherExpensesDialogVisible: false,
      otherAmountDetailTable: []
    }
  },
  methods: {
    logisticsSelectPageJump: function (val) {
      if (val.logistics_select.href !== '') {
        let oInput = document.createElement("input"); //创建一个隐藏input（重要！）
        oInput.value = val.express_no; //赋值
        document.body.appendChild(oInput);
        oInput.select(); // 选择对象
        document.execCommand("Copy"); // 执行浏览器复制命令
        oInput.className = "oInput";
        oInput.style.display = "none";
        this.$message.success('追跡番号をコピーしました');
        if (val.logistics_select.method === 'get') {
          let str = '?';
          let url = this.$fun.deepClone(val.logistics_select.href);
          Object.keys(val.logistics_select.data).forEach((key, index) => {
            if (index === 0) {
              str += key + '=' + val.logistics_select.data[key]
            } else {
              str += '&' + key + '=' + val.logistics_select.data[key]
            }
          });
          url += str;
          window.open(url)
        } else {
          var postForm = document.createElement("form");//创建form表单
          postForm.method = "post";//设置请求方式
          postForm.action = val.logistics_select.href;//设置url
          postForm.target = '_blank';//用来将数据提交到ifrom上（将页面展示在ifrom），删掉这一行会导致页面跳转
          Object.keys(val.logistics_select.data).forEach((key, index) => {
            var custGroup = document.createElement("input");//创建input框，用以提交参数
            custGroup.type = "hidden";//设置input样式
            custGroup.name = key;//这个是传值的名称，要记住
            custGroup.value = val.logistics_select.data[key]//传值
            postForm.appendChild(custGroup);//添加input框到form表单中
          });
          document.body.appendChild(postForm);//添加form表单到body
          postForm.submit();//提交form表单
          document.body.removeChild(postForm);//将body中的form表单删除
        }
      }
    },
    deliveryEncasementDetailsPageJump(val) {
      window.open(`deliveryEncasementDetails?id=${val}`, "_blank")
    },
    //获取国际物流信息
    logisticsMouseenter(item) {

      if (item.activities?.length === 0) {
        this.$api.getLogisticsTrack({
          express_no: item.express_no
        }).then((res) => {
          if (res.code != 0) return
          item.activities = res.data.address_data;
          this.$forceUpdate();
        });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.order {
  .orderLeftContainer {
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    padding: 26px;
    box-sizing: border-box;
    position: relative;

    .hint {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid #B4272B;
      font-size: 10px;
      color: #B4272B;
      cursor: pointer;
      text-align: center;
      line-height: 16px;
      position: absolute;
      left: 4px;
      top: 46px;
    }
  }

  .orderRightContainer {
    background: #FCF1F1;
    border-radius: 10px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(180, 39, 43, 0.68);
    padding: 26px;
    box-sizing: border-box;
    margin-left: 26px;
  }

  .boxNumber {
    width: 60px;
  }

  .actualWeight {
    width: 120px;
  }

  .logisticsMode {
    width: 100px;
  }

  .otherExpenses {
    width: 108px;

    .flexAndCenterAndCenter {
      div:last-child {
        color: #B4272B;
        text-decoration: underline;
        margin-left: 9px;
        cursor: pointer;
      }
    }
  }

  .logisticsNumber {
    width: 140px;
  }

  .billingData {
    width: 120px;
  }

  .header {
    height: 56px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: #B4272B;

    li {
      line-height: 56px;
      text-align: center;
      font-size: 14px;
      color: #FFFFFF;
    }
  }

  .goodsBox,
  {
    overflow: hidden;

    .orderNumberContainer {
      height: 60px;
    }

    .goodsConUl {
      color: #333;
      font-size: 14px;
      border-bottom: 1px solid #DFDFDF;

      li {
        min-height: 70px;
        text-align: center;
        height: auto;
      }
    }
  }
}

.otherExpensesHead {
  ul {
    height: 46px;
    background: #F0F0F0;
    margin-top: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    li {
      text-align: center;
      line-height: 46px;
      font-size: 14px;
      color: #333;
    }

    li:first-child {
      width: 200px;
    }

    li:last-child {
      width: 120px;
    }
  }
}

.otherExpensesFooter {
  max-height: 400px;
  overflow-y: scroll;

  ul {
    height: 50px;

    li {
      text-align: center;
      line-height: 50px;
      font-size: 14px;
      color: #333;
    }

    li:first-child {
      width: 200px;
      min-width: 200px;
    }

    li:last-child {
      width: 120px;
      min-width: 120px;
    }
  }
}

.otherPriceTitle {
  display: flex;
  justify-content: center;

  div {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    padding-bottom: 7px;
    border-bottom: 4px solid #B4272B;
    width: 128px;
  }
}
</style>
