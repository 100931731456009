import { render, staticRenderFns } from "./boxMessage.vue?vue&type=template&id=653c5b34&scoped=true&"
import script from "./boxMessage.vue?vue&type=script&lang=js&"
export * from "./boxMessage.vue?vue&type=script&lang=js&"
import style0 from "./boxMessage.vue?vue&type=style&index=0&id=653c5b34&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "653c5b34",
  null
  
)

export default component.exports